<template>
    <div class="hello">
        <div v-if="isMobiles">
          <vs-row v-if="choixMob == 'import'">
            
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 400px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 16px;"></vs-icon> SOGECIT /</span> COMPTABILITE
                </div>
                
                </vs-card>
              </div>
            
              
  
              <div style="padding: 15px">
                <vs-divider/>
                <p style="text-align: center"><b style="font-size: 20px; letter-spacing: 2px; color: blue">{{eirdata.n_conteneur}}</b></p>
                <p style="text-align: center"><b style="font-size: 14px; letter-spacing: 2px; color: gray">{{eirdata.bl}}</b></p>
                <vs-divider/>
                <vs-table search max-items="20" pagination  :data="donneesDossiers" style="font-size: 12px">
                  <template slot="header">
                    <vs-button color="#C32D39"  icon="person_add" style="padding: 5px; position: absolute; width: 150px;" @click="activePrompt3=true">Nouvelle ligne</vs-button>
                  </template>
                  <template slot="thead">
                    <vs-th style="width: 170px">
                      Mouvement
                    </vs-th>
                    <vs-th style="width: 90px">
                      Mois
                    </vs-th>
                    <vs-th style="width: 130px">
                      Date
                    </vs-th>
                    <vs-th style="width: 130px">
                      Référence
                    </vs-th>
                    <vs-th style="width: 150px">
                      Catégorie
                    </vs-th>
                    <vs-th>
                      Service
                    </vs-th>
                    <vs-th>
                      Tiers
                    </vs-th>
                    <vs-th>
                      Libellé
                    </vs-th>
                    <vs-th>
                      Entrée
                    </vs-th>
                    <vs-th>
                      Sortie
                    </vs-th>
                    <vs-th>
                      Solde
                    </vs-th>
                    <vs-th>
                      Action
                    </vs-th>
                    
                    
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :state="data[indextr].entree.length >= 1000?'success':data[indextr].date_dep == 3 && data[indextr].sortie != data[indextr].sortie ?'danger':null" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].badt">
                        <vs-chip transparent color="success" v-if="data[indextr].entree.length > 2">
                          <vs-button @click="Borderau(data[indextr])" color="green" type="flat" ><b>ENTREE</b></vs-button>
                        </vs-chip>
                        <vs-chip transparent color="danger" v-if="data[indextr].sortie.length > 2">
                          <vs-button @click="Borderau(data[indextr])" color="red" type="flat" ><b>SORTIE</b></vs-button>
                        </vs-chip>
                      </vs-td>

                      <vs-td style="font-size: 12px; background: #f0f0f094" :data="data[indextr].username">
                        <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].domaine.toUpperCase()}}</span>
                      </vs-td>

                      <vs-td style="font-size: 12px; background: #f0f0f094" :data="data[indextr].username">
                        <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].date_dep}}</span>
                      </vs-td>

                      <vs-td style="font-size: 12px; background: #409eff2b; color: blue;" :data="data[indextr].username">
                        <span style="color:red; font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].ref_dep}}</span>
                      </vs-td>

                      <vs-td style="font-size: 12px" :data="data[indextr].username">
                        <b style="font-size: 11px;">{{ data[indextr].cat_dep }}</b>
                      </vs-td>

                      <vs-td style="font-size: 12px;" :data="data[indextr].type_c">
                        {{data[indextr].service}}
                      </vs-td>

                      <vs-td style="font-size: 14px; font-weight: bold;" :data="data[indextr].type_c">
                        {{data[indextr].tiers}}
                      </vs-td>
                      <vs-td style="font-size: 14px; font-weight: bold;" :data="data[indextr].type_c">
                        {{data[indextr].libelle}}
                      </vs-td>

                      <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                        {{data[indextr].entree}}
                      </vs-td>

                      <vs-td style="font-size: 14px; font-weight: bold; color: red;" :data="data[indextr].type_c">
                        
                        <span v-if="data[indextr].sortie.length>1">-</span>
                        {{data[indextr].sortie}}
                      </vs-td>

                      <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                        
                        <span v-if="data[indextr].solde >1">{{data[indextr].solde}}</span>
                        <span v-else style="color: red">{{data[indextr].solde}}</span>
                      </vs-td>

                      <vs-td style="font-size: 12px" :data="data[indextr].type_c">
                        <el-button type="primary" icon="el-icon-refresh-left" circle  @click="Visite(data[indextr])"></el-button><br/>
                        <el-button type="warning" icon="el-icon-document" v-if="data[indextr].recu.length > 10" circle  @click="openExternalPage(data[indextr].recu)"></el-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <vs-divider/>
                <p style="color: red" v-if="eirdata.sortie_port < 5">BADT : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.badt}}</b></p>
                <a target="_blank" v-if="eirdata.eir.length > 10" :href="eirdata.eir"><el-button type="primary">Télécharger EIR</el-button></a>
                <div style="width: 40px; float: right; margin-bottom: 30px;">
                  <!-- <a href="#">Hola mundo</a>
                  <el-dropdown class="button-bas">
                    <span class="el-dropdown-link">
                      <vs-button  radius color="warning" type="filled" icon="public"></vs-button>
                    </span>
                    
                    <el-dropdown-menu slot="dropdown">
                      <router-link to="/"><el-dropdown-item icon="el-icon-caret-right">Import</el-dropdown-item></router-link>
                      <router-link to="/benne_export"><el-dropdown-item icon="el-icon-caret-left">Export</el-dropdown-item></router-link>
                      <router-link to="/comptabilite"><el-dropdown-item icon="el-icon-money">Dépense</el-dropdown-item></router-link>
                      <router-link to="/propremoy"><el-dropdown-item icon="el-icon-receiving">Propre Moy</el-dropdown-item></router-link>
                    </el-dropdown-menu>
                     
                  </el-dropdown>
                    -->
                </div>
              </div>
  
             
            </vs-col>
          </vs-row>
          <vs-row v-if="choixMob == 'export'">
            
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 400px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 16px;"></vs-icon> SOCOGETRA /</span> EIR EXPORT
                </div>
                </vs-card>
              </div>
              
              <el-input style="margin: 100px; margin-left:5%; width: 90%" placeholder="Numéro Conteneur Export" v-model="eirdatas.conteneur" class="input-with-select">
                <el-button slot="append" @click="searchEire()" icon="el-icon-search"></el-button>
              </el-input>
  
              <div style="margin-top: -100px; padding: 15px">
                <vs-divider/>
                <p style="text-align: center"><b style="font-size: 20px; letter-spacing: 2px; color: blue">{{eirdata.n_conteneur}}</b></p>
                <p style="text-align: center"><b style="font-size: 14px; letter-spacing: 2px; color: gray">{{eirdata.n_doc}}</b></p>
                <vs-divider/>
                <p>Client : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.client}}</b></p>
                <p>Armateur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.armateur}}</b></p>
                <p>Lieu de livraison : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.lieu_posit}} / {{eirdata.mode}} / {{eirdata.type_c}}'</b></p>
                <p>Chauffeur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.chauffeur}} / {{eirdata.chauffeur2}}</b></p>
                <p>Camion : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.camion}} / {{eirdata.camion2}}</b></p>
                <p>Positionnement : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.date_posit}}</b></p>
                <p>Rélévage : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.entree_terminal}}</b></p>
                <p>Contact : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.contact}}</b></p>
                <input v-if="chargeeir == true" @change="uploadImage($event, 'a')" type="file" placeholder="Choisir le fichier" required ref="file" name="image" id="uploadInput" class="form-control">
                          <vs-progress indeterminate color="primary" v-show="chargeFichier == 1">primary</vs-progress>
                          <vs-progress :percent="100" color="primary" v-show="chargeFichier == 2">primary</vs-progress>
                          <span v-show="chargeFichier == 2"><i class="el-icon-circle-check"></i> Eir Succès</span>
                          <span v-if="eirdata.eir.length < 10"><vs-button color="primary" type="flat" v-if="chargeeir == false" style="font-size: 20px" @click="chargeeir=true" >Ajouter EIR</vs-button></span>
                <vs-divider/>
                <p style="color: red" v-if="eirdata.sortie_port < 5">BADT : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.badt}}</b></p>
                <a target="_blank" v-if="eirdata.eir.length > 10" :href="eirdata.eir"><el-button type="primary">Télécharger EIR</el-button></a>
                <div style="width: 40px; float: right; margin-bottom: 30px;">
                  <el-dropdown class="button-bas">
                    <span class="el-dropdown-link">
                      <vs-button  radius color="warning" type="filled" icon="public"></vs-button>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-caret-right" @click="choixMob = 'import'">Import</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-caret-left"  @click="choixMoba('export')">Export</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-money"  @click="choixMob = 'depense'">Dépense</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
               
                </div>
              </div>
  
             
            </vs-col>
          </vs-row>
          <vs-row v-if="choixMob == 'depense'">
            
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 400px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 16px;"></vs-icon> SOCOGETRA /</span> EIR RETOUR
                </div>
                </vs-card>
              </div>
              
              <el-input style="margin: 100px; margin-left:5%; width: 90%" placeholder="Numéro Conteneur" v-model="eirdatas.conteneur" class="input-with-select">
                <el-button slot="append" @click="searchEir()" icon="el-icon-search"></el-button>
              </el-input>
  
              <div style="margin-top: -100px; padding: 15px">
                <vs-divider/>
                <p style="text-align: center"><b style="font-size: 20px; letter-spacing: 2px; color: blue">{{eirdata.n_conteneur}}</b></p>
                <p style="text-align: center"><b style="font-size: 14px; letter-spacing: 2px; color: gray">{{eirdata.bl}}</b></p>
                <vs-divider/>
                <p>Client : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.client}}</b></p>
                <p>Armateur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.armateur}}</b></p>
                <p>Lieu de livraison : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.zone_livraison}} / {{eirdata.mode}} / {{eirdata.type_c}}'</b></p>
                <p>Chauffeur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.chauffeur}}</b></p>
                <p>Camion : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.camion}}</b></p>
                <p>Sortie terminal : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.sortie_port}}</b></p>
                <p>Entrée terminal : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.date_recup}}</b></p>
                <p>Contact : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.observation}}</b></p>
                <input v-if="chargeeir == true" @change="uploadImage($event, 'a')" type="file" placeholder="Choisir le fichier" required ref="file" name="image" id="uploadInput" class="form-control">
                          <vs-progress indeterminate color="primary" v-show="chargeFichier == 1">primary</vs-progress>
                          <vs-progress :percent="100" color="primary" v-show="chargeFichier == 2">primary</vs-progress>
                          <span v-show="chargeFichier == 2"><i class="el-icon-circle-check"></i> Eir Succès</span>
                          <span v-if="eirdata.eir.length < 10"><vs-button color="primary" type="flat" v-if="chargeeir == false" style="font-size: 20px" @click="chargeeir=true" >Ajouter EIR</vs-button></span>
                <vs-divider/>
                <p style="color: red" v-if="eirdata.sortie_port < 5">BADT : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.badt}}</b></p>
                <a target="_blank" v-if="eirdata.eir.length > 10" :href="eirdata.eir"><el-button type="primary">Télécharger EIR</el-button></a>
                <div style="width: 40px; float: right; margin-bottom: 30px;">
                  <el-dropdown class="button-bas">
                    <span class="el-dropdown-link">
                      <vs-button  radius color="warning" type="filled" icon="public"></vs-button>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-caret-right" @click="choixMob = 'import'">Import</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-caret-left"  @click="choixMob = 'export'">Export</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-money"  @click="choixMob = 'depense'">Dépense</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
               
                </div>
              </div>
  
             
            </vs-col>
          </vs-row>
        </div>
        <div v-else class="screenUse">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 100%; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> DOSSIERS /</span> IMPORT
                  <vs-button to="/conteneur_ordres" color="#C32D39"  icon="list" style="padding: 5px; position: absolute; width: 150px;" @click="popupActivo2=true">Historique</vs-button>
                  <el-date-picker
                      v-model="dateSearch"
                      type="daterange"
                      range-separator="à"
                      start-placeholder="Date de début"
                      end-placeholder="Date de fin">
                    </el-date-picker>
                    <el-button icon="el-icon-search" circle @click="listeFiltre()"></el-button>
                </div>
                <span style="float: right; margin-right: 150px; margin-top: -20px">
                  <vs-button color="#C32D39"  icon="person_add" style="padding: 5px; position: absolute; width: 150px;" @click="activePrompt3=true">Nouvelle ligne</vs-button>
                </span>
                <span style="float: right; margin-right: 350px; margin-top: -20px">
                  <vs-button color="#C32D39"  icon="print" style="padding: 5px; position: absolute; width: 150px;" @click="generateReport">Imprimer</vs-button>
                </span>  
                </vs-card>
              </div>
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">
  
                  <vs-divider position="left">BILAN PAR TYPE DE <b>DEPENSE</b></vs-divider>
                  <vs-row>

                    <vs-col vs-w="1.4"  v-for="(item, i) in donneesServ" :key="i">
                      <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 3px solid blue;">
                           <vs-row class="space-zone2">
                              <vs-col vs-w="12" style="display: grid;">
                                <vs-row class="space-zone2">
                                  <vs-col vs-w="12" style="display: grid;">
                                    <span class="sousTitre-zone" style="width: 100%"><vs-icon style=" padding: 3px; font-size: 15px" icon="inventory_2" color="white" bg="orange" round></vs-icon> <b style="font-size: 13px; color: blue" @click="filterService(item.service)">{{ item.service }}</b><br><br><br>
                                    <span class="prix-zone">{{ formatPrice(item.total_entree) }} <span style="font-weight: normal;"></span></span>
                                    <span class="prix-zone" style="float: right; color: orange">{{ formatPrice(item.total_sortie) }} <span style="font-weight: normal;"></span></span></span>
                                  </vs-col>
                                 
                                </vs-row>
                              </vs-col>
                            </vs-row>
                      </el-card>

                    </vs-col>
                      
    
                     
                    </vs-row>
                    <vs-row>
                      <vs-col vs-w="1.15"  v-for="(item, i) in donneesCat" :key="i">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 3px solid #13ce66;">
                            <vs-row class="space-zone2">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone2">
                                    <vs-col vs-w="12" style="display: grid;">
                                      <span class="sousTitre-zone" style="width: 100%"><vs-icon style=" padding: 3px; font-size: 15px" icon="inventory_2" color="white" bg="orange" round></vs-icon> <b style="font-size: 13px" @click="filterData(item.cat_dep)">{{ item.cat_dep }}</b><br><br><br>
                                      <span class="prix-zone"  style="color: black">{{ formatPrice(item.total_sortie) }} <span style="font-weight: normal;"></span></span>
                                      </span>
                                    </vs-col>
                                  
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>
                    </vs-row>
  
                    <vs-divider position="left">.</vs-divider>
  
  
  
                    <table border="1" ref="exportable_table" v-show="false">
                      <tbody >
                      <tr>
                          <th>N°</th>
                          <th>MOIS</th>
                          <th>DATE</th>
                          <th>REFERENCE</th>
                          <th>CATEGORIE</th>
                          <th>SERVICE</th>
                          <th>TIERS</th>
                          <th>LIBELLE</th>
                          <th>ENTREE</th>
                          <th>SORTIE</th>
                          <th>SOLDE</th>
                      </tr>
                      <tr v-for="(item, i) in donneesDossiers" :key="i">
                        <td >{{i}}</td>
                          <td>{{item.domaine}}</td>
                          <td>{{item.date_dep}}</td>
                          <td>{{item.ref_dep}}</td>
                          <td>{{item.cat_dep}}</td>
                          <td>{{item.service}}</td>
                          <td>{{item.tiers}}</td>
                          <td>{{item.libelle}}</td>
                          <td>{{item.entree}}</td>
                          <td>{{item.sortie}}</td>
                          <td>{{item.solde}}</td>
                      </tr>
                      </tbody>
                  </table>

                  <table border="1" ref="exportable_tablee" v-show="false">
                    <tbody >
                    <tr>
                        <th>N°</th>
                        <th>MOIS</th>
                        <th>DATE</th>
                        <th>REFERENCE</th>
                        <th>CATEGORIE</th>
                        <th>SERVICE</th>
                        <th>TIERS</th>
                        <th>LIBELLE</th>
                        <th>ENTREE</th>
                        <th>SORTIE</th>
                    </tr>
                    <tr v-for="(item, i) in filteredData" :key="i">
                      <td >{{i}}</td>
                        <td>{{item.domaine}}</td>
                        <td>{{item.date_dep}}</td>
                        <td>{{item.ref_dep}}</td>
                        <td>{{item.cat_dep}}</td>
                        <td>{{item.service}}</td>
                        <td>{{item.tiers}}</td>
                        <td>{{item.libelle}}</td>
                        <td>{{item.entree}}</td>
                        <td>{{item.sortie}}</td>
                    </tr>
                    </tbody>
                </table>
  
                  <div style="background-color: white;">
                    <vs-row>
                      
                      <vs-col vs-w="12" style="display: grid;">
  
                        <vs-table search max-items="20" pagination  :data="donneesDossiers" style="font-size: 12px">
                          <template slot="header">
                              <h3>HISTORIQUE DE CAISSE <el-link type="success" @click="ExportExcel('xls')"><img src="../../assets/excel.png" width="14"> EXPORTER</el-link> </h3>
                              <span style="margin-left: 25px; cursor: pointer" @click="activePrompt6=true"><vs-chip color="warning">NOUVELLE CATEGORIE <b style="margin-left: 2px; margin-rigth: 2px"> </b></vs-chip></span>
                          </template>
                          <template slot="thead">
                            <vs-th style="width: 170px">
                              Mouvement
                            </vs-th>
                            <vs-th style="width: 90px">
                              Mois
                            </vs-th>
                            <vs-th style="width: 130px">
                              Date
                            </vs-th>
                            <vs-th style="width: 130px">
                              Référence
                            </vs-th>
                            <vs-th style="width: 150px">
                              Catégorie
                            </vs-th>
                            <vs-th>
                              Service
                            </vs-th>
                            <vs-th>
                              Tiers
                            </vs-th>
                            <vs-th>
                              Libellé
                            </vs-th>
                            <vs-th>
                              Entrée
                            </vs-th>
                            <vs-th>
                              Sortie
                            </vs-th>
                            <vs-th>
                              Solde
                            </vs-th>
                            <vs-th>
                              Action
                            </vs-th>
                            
                            
                          </template>
        
                          <template slot-scope="{data}">
                            <vs-tr :state="data[indextr].entree.length >= 1000?'success':data[indextr].date_dep == 3 && data[indextr].sortie != data[indextr].sortie ?'danger':null" :key="indextr" v-for="(tr, indextr) in data">
                              <vs-td :data="data[indextr].badt">
                                <vs-chip transparent color="success" v-if="data[indextr].entree.length > 2">
                                  <vs-button @click="Borderau(data[indextr])" color="green" type="flat" ><b>ENTREE</b></vs-button>
                                </vs-chip>
                                <vs-chip transparent color="danger" v-if="data[indextr].sortie.length > 2">
                                  <vs-button @click="Borderau(data[indextr])" color="red" type="flat" ><b>SORTIE</b></vs-button>
                                </vs-chip>
                              </vs-td>
        
                              <vs-td style="font-size: 12px; background: #f0f0f094" :data="data[indextr].username">
                                <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].domaine.toUpperCase()}}</span>
                              </vs-td>

                              <vs-td style="font-size: 12px; background: #f0f0f094" :data="data[indextr].username">
                                <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].date_dep}}</span>
                              </vs-td>

                              <vs-td style="font-size: 12px; background: #409eff2b; color: blue;" :data="data[indextr].username">
                                <span style="color:red; font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].ref_dep}}</span>
                              </vs-td>
        
                              <vs-td style="font-size: 12px" :data="data[indextr].username">
                                <b style="font-size: 11px;">{{ data[indextr].cat_dep }}</b>
                              </vs-td>
        
                              <vs-td style="font-size: 12px;" :data="data[indextr].type_c">
                                {{data[indextr].service}}
                              </vs-td>
        
                              <vs-td style="font-size: 14px; font-weight: bold;" :data="data[indextr].type_c">
                                {{data[indextr].tiers}}
                              </vs-td>
                              <vs-td style="font-size: 14px; font-weight: bold;" :data="data[indextr].type_c">
                                {{data[indextr].libelle}}
                              </vs-td>

                              <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                                {{ formatPrice(data[indextr].entree) }}
                              </vs-td>

                              <vs-td style="font-size: 14px; font-weight: bold; color: red;" :data="data[indextr].type_c">
                                
                                <span v-if="data[indextr].sortie.length>1">-</span>
                                {{ formatPrice(data[indextr].sortie) }}
                                
                              </vs-td>

                              <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                                
                                <span v-if="data[indextr].solde >1">{{ formatPrice(data[indextr].solde) }}</span>
                                <span v-else style="color: red">{{formatPrice(data[indextr].solde)}}</span>
                              </vs-td>

                              <vs-td style="font-size: 12px" :data="data[indextr].type_c">
                                <el-button type="primary" icon="el-icon-refresh-left" circle  @click="Visite(data[indextr])"></el-button>
                                <el-button type="danger" icon="el-icon-delete-solid" circle  @click="supBon(data[indextr].id_dep)"></el-button>
                                <el-button type="warning" icon="el-icon-document" v-if="data[indextr].recu.length > 10" circle  @click="openExternalPage(data[indextr].recu)"></el-button>
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>

                        
                          <vs-prompt
                          title="Nouvelle Catégorie"
                          color="danger"
                          @cancel="valMultipe.value1='',valMultipe.value2=''"
                          @accept="Ajoutcat"
                          accept-text="Ajouter"
                          cancel-text="Annuler"
                          @close="close"
                          :active.sync="activePrompt6">
                          <div class="con-exemple-prompt">
                            <div style="padding: 10px 0px;">
                              
                              <label>Catégorie</label>
                                  <el-input style="width: 100%;" v-model="cat" placeholder="Nom & Prenom">
                                  </el-input>

                            </div>
                          </div>
                          </vs-prompt>
                          
                        
                       
                      </vs-col>
                    </vs-row>
                  </div>
                  
  
                
                <vs-divider/>
                
              </div>
  
            </vs-col>
          </vs-row>
  
          <vs-prompt
            @cancel="val=''"
            @accept="Facturons"
            @close="close"
            :title="'Facturation '+ dossierRef"
            :active.sync="facturation">
            <div class="con-exemple-prompt">
                N° de la facture
              <vs-input placeholder="Référence" v-model="refFacture"/>
            </div>
          </vs-prompt>
  
          <vs-popup classContent="popup-example"  title="BILAN CLIENT" :active.sync="popupActivo2">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  {{ ClientChoisi.client }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  {{ ClientChoisi.produit }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Bons</label>
                  {{ ClientChoisi.total_enregistrements }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label>
                  {{ ClientChoisi.total_poids }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Prix Unitaire</label>
                  <el-input style="width: 100%;" v-model="prixUnit" placeholder="Prix">
                    </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Prix total</label>
                  {{ ClientChoisi.total_poids * prixUnit }}
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="generateReport()" color="primary" type="filled">Enregistrer le dossier</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>
  
          <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="hee hee"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="800px"

              @progress="onProgress($event)"
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2Pdf"
          >
              <section slot="pdf-content">
                <div>
                  <div style="width: 28cm;  margin-left: .5cm; margin-top: 20px;" id="html-to-pdf">
                    <div style="width: 100%; padding: 10px">
                      <div style="float: left; display: block;">
                        <img src="../../assets/sogecit.png" width="185">
                      </div>
                      <div style="float: left; padding: 12px;">
                        <span style="font-weight: bold; font-size: 19px; font-family: 'Nunito Sans', sans-serif;">HISTORIQUE DES DEPENSES - <span><b>a </b></span><span style="color: red; font-size: 18px">( total poids - <b>{{ totalPoids }} KG</b> )</span></span>
                      </div>
                    </div>
                  .
                    <h2></h2>
                    <vs-divider/>
                    <el-table
                          ref="multipleTable"
                          :data="donneesDossiers"
                          stripe
                          style="font-size: 12px; margin-left: 1px; font-family: Helvetica;"
                          @selection-change="handleSelectionChange">
                          <el-table-column
                            label="Mouvt">
                            <template slot-scope="scope">
                              <span style="color:#13ce66" v-if="scope.row.entree.length > 2">ENTREE</span>
                              <span style="color:#C32D39" v-if="scope.row.sortie.length > 2">SORTIE</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                              property="name"
                              label="Mois">
                              <template slot-scope="scope">
                              
                              <el-link type="primary" style="font-size:12px"><i class="el-icon-document"></i> {{ scope.row.domaine.toUpperCase() }}</el-link>
                              
                              </template>
                          </el-table-column>
                          <el-table-column
                            label="Date">
                            <template slot-scope="scope">
                                {{ scope.row.date_dep }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Catégorie">
                            <template slot-scope="scope">
                                {{ scope.row.cat_dep }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Service">
                            <template slot-scope="scope">
                                {{ scope.row.service }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Tiers">
                            <template slot-scope="scope">
                                {{ scope.row.tiers }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Entrée">
                            <template slot-scope="scope">
                                {{ scope.row.entree }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Sortie">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sortie.length>1">-</span>
                                {{ scope.row.sortie }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Solde">
                            <template slot-scope="scope">
                              <span v-if="scope.row.solde >1" style="color: blue">{{scope.row.solde}}</span>
                              <span v-else style="color: red">{{scope.row.solde}}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                  </div>
                </div>
                  
              </section>
          </vue-html2pdf>
        </div>

        <vs-prompt
                          title="Nouvelle entrée"
                          color="danger"
                          @cancel="valMultipe.value1='',valMultipe.value2=''"
                          @accept="Ajoutdep"
                          accept-text="Ajouter"
                          cancel-text="Annuler"
                          @close="close"
                          :active.sync="activePrompt3">
                          <div class="con-exemple-prompt">
                            <div style="padding: 10px 0px;">
                              
                              <label>Service</label>
                              <vs-select
                                      style="width: 100%;"
                                      placeholder="Service"
                                      class="selectExample"
                                      label-placeholder="Autocomplete"
                                      autocomplete
                                      v-model="form.service"
                                      >
                                      <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in serviceListe" />
                                    </vs-select>

                              
                              <vs-row>
                                <vs-col vs-w="6" style="padding-right: 2px;">
                                  <label>Date</label>
                                  <el-input style="width: 100%;" type="date" v-model="form.date_dep" placeholder=">Date">
                                  </el-input>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Référence</label>
                                  <el-input style="width: 100%;" v-model="form.ref_dep" placeholder="Référence">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <vs-row>
                                <vs-col vs-w="6" style="padding-right: 2px;">
                                  <label>Catégorie</label>
                                    <vs-select
                                      style="width: 100%;"
                                      placeholder="Catégorie"
                                      class="selectExample"
                                      label-placeholder="Autocomplete"
                                      autocomplete
                                      v-model="form.cat_dep"
                                      >
                                      <vs-select-item :key="index" :value="item.libelle" :text="item.libelle" v-for="item,index in listeCat" />
                                    </vs-select>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Tiers</label>
                                  <el-input style="width: 100%;" v-model="form.tiers" placeholder="Nom & Prenom">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <label>Libellé</label>
                              <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Libellé"
                                v-model="form.libelle">
                              </el-input>

                              <vs-row>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Entrée</label>
                                  <el-input style="width: 100%;" v-model="form.entree" type="number" placeholder="valeur">
                                  </el-input>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Sortie</label>
                                  <el-input style="width: 100%;" v-model="form.sortie" placeholder="valeur">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <label>Reçu / Facture</label><br>
                            <input @change="uploadImage($event, 'a')" type="file" placeholder="Choisir le fichier" required ref="file" name="image" id="uploadInput" class="form-control">
                            <vs-progress indeterminate color="primary" v-show="chargeFichier == 1">primary</vs-progress>
                            <vs-progress :percent="100" color="primary" v-show="chargeFichier == 2">primary</vs-progress>
                            <span v-show="chargeFichier == 2"><i class="el-icon-circle-check"></i> Fichier Succès</span>

                              

                              
                            </div>
                          </div>
                          </vs-prompt>

                          <vs-prompt
                          title="Modification"
                          color="danger"
                          @cancel="valMultipe.value1='',valMultipe.value2=''"
                          @accept="Modifdep"
                          accept-text="Modifier"
                          cancel-text="Annuler"
                          @close="close"
                          :active.sync="activePrompt5">
                          <div class="con-exemple-prompt">
                            <div style="padding: 10px 0px;">
                              
                              <label>Service</label>
                              <vs-select
                                      style="width: 100%;"
                                      placeholder="Service"
                                      class="selectExample"
                                      label-placeholder="Autocomplete"
                                      autocomplete
                                      v-model="form.service"
                                      >
                                      <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in serviceListe" />
                                    </vs-select>

                              
                              <vs-row>
                                <vs-col vs-w="6" style="padding-right: 2px;">
                                  <label>Date</label>
                                  <el-input style="width: 100%;" type="date" v-model="form.date_dep" placeholder=">Date">
                                  </el-input>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Référence</label>
                                  <el-input style="width: 100%;" v-model="form.ref_dep" placeholder="Référence">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <vs-row>
                                <vs-col vs-w="6" style="padding-right: 2px;">
                                  <label>Catégorie</label>
                                    <vs-select
                                      style="width: 100%;"
                                      placeholder="Catégorie"
                                      class="selectExample"
                                      label-placeholder="Autocomplete"
                                      autocomplete
                                      v-model="form.cat_dep"
                                      >
                                      <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in categorieListe" />
                                    </vs-select>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Tiers</label>
                                  <el-input style="width: 100%;" v-model="form.tiers" placeholder="Nom & Prenom">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <label>Libellé</label>
                              <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Libellé"
                                v-model="form.libelle">
                              </el-input>

                              <vs-row>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Entrée</label>
                                  <el-input style="width: 100%;" v-model="form.entree" type="number" placeholder="valeur">
                                  </el-input>
                                </vs-col>
                                <vs-col vs-w="6" style="padding-left: 2px;">
                                  <label>Sortie</label>
                                  <el-input style="width: 100%;" v-model="form.sortie" placeholder="valeur">
                                  </el-input>
                                </vs-col>
                              </vs-row>

                              <label>Reçu / Facture</label><br>
                            <input @change="uploadImage($event, 'a')" type="file" placeholder="Choisir le fichier" required ref="file" name="image" id="uploadInput" class="form-control">
                            <vs-progress indeterminate color="primary" v-show="chargeFichier == 1">primary</vs-progress>
                            <vs-progress :percent="100" color="primary" v-show="chargeFichier == 2">primary</vs-progress>
                            <span v-show="chargeFichier == 2"><i class="el-icon-circle-check"></i> Fichier Succès</span>

                              

                              
                            </div>
                          </div>
                          </vs-prompt>
       
    </div>
  </template>
  
  <script>
  import { isMobile } from 'mobile-device-detect';
  import * as XLSX from "xlsx";
  import VueHtml2pdf from 'vue-html2pdf'
  import html2pdf from "html2pdf.js";
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas';
  import axios from 'axios';
  import NavBar from '@/components/conteneurs/Navbar.vue'
  export default {
    name: 'HelloWorld',
    components: {
      NavBar,
      VueHtml2pdf
    },
    computed: {
      users() {
        return this.$store.state.users;
          },
        },
    data() {
      return {
        popupActivo4:false, 
        valueSelect: '',
        valueService: '',
        activePrompt3:false,
        activePrompt5: false,
        activePrompt6: false,
        cat: '',
        choixMob: 'import',
        isMobiles : isMobile,
        deletedep: 0,
        search: '',
        chargeeir: false,
        eirdata: {
          client: '',
          eir: ''
        },
        eirdatas: {
          conteneur: '',
          eir: ''
        },
        chargeFichier : 0,
        chargeFichier1 : 0,
        chargeFichier2 : 0,
        chargeFichier3 : 0,
        container: '',
        refFacture: '',
        dossierRef: '',
        facturation: false,
        counter: false,
        prixUnit: 0,
        dateSearch: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
          ],
        popupActivo2:false,
        popupActivo3:false,
        activePrompt9:false,
        select1:3,
        options1:[
          {text:'IT',value:0},
          {text:'Blade Runner',value:2},
          {text:'Thor Ragnarok',value:3},
        ],
        formDate: {
          dateBen: ''
        },
        checkTrue: true,
        donneesClient: null,
        listeCat: null,
        donneesCliProd: null,
        checkFalse: false,
        totalVoyages:0,
        donnees: null,
        don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Camion: null,
        donneesStats: {
          dn_carb: [{"total":"0","litre":"0","prix":"0"}],
          dn_dep: [{"total":"0","prix":"0"}],
          dn_voyages: [{"chauffeur":"","client":"","dossier":"0","id_ben":"0","lieu_chargement":"0","lieu_dechargement":"","n_bl":"","n_matricule":"","observation":"0","par":"0","poids":"0","prix":"0","produit":"0"}],
          dn_clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
          nb_carb: 0,
          nb_dep: 0,
          nb_voyages: 0,
          tableau: [{"camion":"0","chauffeur":"0","date_carb":"0","details":"0","id_camion":"0","id_carburant":"0","imatriculation":"0","litre":"0","marque":"0","nombre_de_bennes":"0","prix":"0"}],
        },
        donneesStatsMois: null,
        donneesStatsSemaine: null,
        donneesArmateurs: null,
        donneesSheet: null,
        elementChoisi: {
          date_recept: ''
        },
        donneesZones: null,
        donneesCamion: null,
        ClientChoisi: {
          client: '',
          produit: '',
          total_enregistrements: '',
          total_poids: ''
        },
        donneesChauf: null,
        donneesDossiers: null,
        filteredData: [],
        donneesExcel: null,
        donneesCat: null,
        donneesServ: null,
        donneesDossiersBadt: null,
        donneesDossiersNbadt: null,
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: '',
          value2: '',
          title: 'Growth this year',
        activeName: 'first',
        chartData: {
          labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
          datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
        },
        chartOptions: {
          responsive: true
        },
        form: {
          date_dep: '',
          ref_dep: '',
          cat_dep: '',
          service: '',
          tiers: '',
          libelle: '',
          entree: '',
          sortie: '',
          recu: '',
          },
          serviceListe: [{
            value: 'CONTENEUR',
            label: 'CONTENEUR'
          }, {
            value: 'CITERNE',
            label: 'CITERNE'
          }, {
            value: 'BENNE',
            label: 'BENNE'
          }, {
            value: 'DIVERS',
            label: 'DIVERS'
          }],
          categorieListe: [{
            value: 'CARBURANT',
            label: 'CARBURANT'
          }, {
            value: 'FRAIS-ROUTE',
            label: 'FRAIS-ROUTE'
          }, {
            value: 'MATERIEL',
            label: 'MATERIEL'
          }, {
            value: 'BUREAUTIQUE',
            label: 'BUREAUTIQUE'
          }, {
            value: 'LOCATION',
            label: 'LOCATION'
          }, {
            value: 'ASSURANCE',
            label: 'ASSURANCE'
          }, {
            value: 'PNEU',
            label: 'PNEU'
          }, {
            value: 'REPARATION',
            label: 'REPARATION'
          }, {
            value: 'TRANSIT',
            label: 'TRANSIT'
          }, {
            value: 'STATIONNEMENT',
            label: 'STATIONNEMENT'
          }, {
            value: 'NOTAIRE',
            label: 'NOTAIRE'
          }, {
            value: 'DON/SUB',
            label: 'DON/SUB'
          }, {
            value: 'INTERET-BANQUE',
            label: 'INTERET-BANQUE'
          }, {
            value: 'VIDANGE',
            label: 'VIDANGE'
          }, {
            value: 'FAMILLE',
            label: 'FAMILLE'
          }, {
            value: 'PERSONNEL',
            label: 'PERSONNEL'
          }, {
            value: 'DIVERS',
            label: 'DIVERS'
          }],
          type_cont: [{
            value: "20 'OPEN",
            label: "20 'OPEN"
          }, {
            value: "40 'OPEN",
            label: "40 'OPEN"
          }, {
            value: "20 'FLAT",
            label: "20 'FLAT"
          },{
            value: "40 'FLAT",
            label: "40 'FLAT"
          }, {
            value: "20 'DRY",
            label: "20 'DRY"
          }, {
            value: "40 'DRY",
            label: "40 'DRY"
          }, {
            value: "20 'FRIGO",
            label: "20 'FRIGO"
          }, {
            value: "40 'FRIGO",
            label: "40 'FRIGO"
          }],
          armateur: [{
            value: "Evergreen",
            label: "Evergreen"
          }, {
            value: "Maersk",
            label: "Maersk"
          }, {
            value: "Safmarine",
            label: "Safmarine"
          },{
            value: "Cosco",
            label: "Cosco"
          }, {
            value: "One",
            label: "One"
          }, {
            value: "PIL",
            label: "PIL"
          }, {
            value: "Oma",
            label: "Oma"
          }, {
            value: "Medlog",
            label: "Medlog"
          }],
        tableData: [{
            date: '2016-05-03',
            name: 'TRAORE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-02',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-04',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-01',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Annulé'
          }, {
            date: '2016-05-08',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-06',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-07',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }],
      }
    },
    methods: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      filterData(value) {
        // Filter the data array based on the condition cat_dep === 'VIDANGE'
        this.filteredData = this.donneesDossiers.filter(item => item.cat_dep === value);
        this.valueSelect = value;
        setTimeout(() => {
            this.ExportExcel2();
        }, 2000);
      },

      filterService(value) {
        // Filter the data array based on the condition cat_dep === 'VIDANGE'
        this.filteredData = this.donneesDossiers.filter(item => item.service === value);
        this.valueService = value;
        setTimeout(() => {
            this.ExportExcel3();
        }, 2000);
      },
  
      choixMoba(a) {
        this.choixMob = a;
      },
  
      searchEir() {
        this.chargeeir = false;
        this.$vs.loading({
            type:'point',
            })
            axios.get('https://sygestra.com/appi_sygestra/cherchetc.php?tc='+this.eirdatas.conteneur+'&timestamp=' + new Date().getTime())
            .then((response) => {
              this.eirdata = response.data.data[0];
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
      },
  
      searchEire() {
        this.chargeeir = false;
        this.$vs.loading({
            type:'point',
            })
            axios.get('https://sygestra.com/appi_sygestra/cherchetce.php?tc='+this.eirdatas.conteneur+'&timestamp=' + new Date().getTime())
            .then((response) => {
              this.eirdata = response.data.data[0];
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
      },

      openExternalPage(item) {
        // Remplacez 'URL_DE_LA_PAGE' par l'URL réelle de la page externe
        const externalURL = item;
        window.open(externalURL, '_blank'); // Cette ligne ouvrira la page externe dans un nouvel onglet
      },
  
      uploadImage(e, a) {
            if(a === 'a') {
              this.chargeFichier = 1;
            }
            if(a === 'b') {
              this.chargeFichier2 = 1;
            }
            if(a === 'c') {
              this.chargeFichier3 = 1;
            }
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image = files[0]; 

            let formData = new FormData();
            let uploadPreset = "dsegjq9c"
            let cloudinaryUrl = "https://api.cloudinary.com/v1_1/r-sidence-meubl-e/upload"
            this.annee = this.printYear();
            formData.append('file', this.image);
            formData.append('public_id', this.annee);
            formData.append('upload_preset', uploadPreset)

            axios.post(cloudinaryUrl, formData)
            .then(res => {
                if(a === 'a') {
                  this.chargeFichier = 2;
                  let fichierUrl = res.data;
                  this.form.recu = fichierUrl.url;
                }
                if(a === 'b') {
                  this.chargeFichier2 = 2;
                  let fichierUrl = res.data;
                  this.form.fichier_piece = fichierUrl.url;
                }
                if(a === 'c') {
                  this.chargeFichier3 = 2;
                  this.form.email = null;
                  this.form.email = res.data.url;
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
  
          printYear: function () {
              return new Date().getFullYear() + 
              '' + new Date().getMonth()+ 
              '' + new Date().getDate()+
              '' + new Date().getHours()+
              '' + new Date().getMinutes()+
              '' + new Date().getMilliseconds()+
              '' + new Date().getSeconds();
        },

        listeCats () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sogecit.net/appi_socogetra/categorie_sogecit.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.listeCat = response.data.data.reverse();
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

        Ajoutcat() {
          const form = {
                cat : this.cat
              };
              axios.post('https://sogecit.net/appi_socogetra/categorie_sogecit.php', form)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.listeCats();
                  this.$notify.success({
                  title: 'Entrée Ajouté !',
                  message: '',
                  showClose: false
                  });
                  this.listeDossiers();
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
        },

        Ajoutdep() {
            this.form.entreprise = this.users.n_ent;
              axios.post('https://sogecit.net/appi_socogetra/depense_sogecit.php', this.form)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'Entrée Ajouté !',
                  message: '',
                  showClose: false
                  });
                  this.listeDossiers();
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

          supBon(id, c) {
              this.deletedep = id;
              this.$vs.dialog({
              color:'danger',
              title: `Suppression`,
              text: 'Êtes vous sûre de vouloir supprimer ? ' + c,
              accept:this.Delete
            })
          },

          Delete() {
             axios.delete('https://sogecit.net/appi_socogetra/depense_sogecit.php?id='+this.deletedep)
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    this.popupActivo2 = false;
                    this.listeDossiers();
                    this.$notify.success({
                    title: 'Supprimé avec Succès',
                    message: '',
                    showClose: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    this.$vs.loading.close()
                });
          },

        Modifdep() {

              axios.put('https://sogecit.net/appi_socogetra/depense_sogecit.php', this.form)
              .then((response) => {
                  console.log(response);
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'Modification effectué',
                  message: '',
                  showClose: false
                  });
                  this.listeDossiers();
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },
  
      posteir() {
        axios.post('https://sygestra.com/appi_sygestra/cherchetc.php', this.eirdatas)
              .then((response) => {
                  console.log(response);
                  this.popupActivo2 = false,
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'EIR OK!',
                  message: '',
                  showClose: false
                  });
                 // const lala = this.eirdatas.conteneur;
                  this.chargeeir = false;
                  this.eirdatas.eir = "";
                  this.searchEir();
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
      },
  
      choixPeriode(a) {
        this.periode = a;
        console.log(this.periode);
      },
      
      Visite(select) {
        this.form = {
          date_dep: select.date_dep,
          ref_dep: select.ref_dep,
          cat_dep: select.cat_dep,
          service: select.service,
          tiers: select.tiers,
          libelle: select.libelle,
          entree: select.entree,
          sortie: select.sortie,
          id_dep: select.id_dep,
          recu: select.recu,
          },
       this.activePrompt5 = true;
      },
  
      armateurs(id) {
        this.$router.push({ name: 'AD_Armateurs', params: { element: id } });
      },
  
      dateCherche() {
        const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
        this.$router.push({ name: 'Conteneur_recherche', params: { debut: dte_db, fin: dte_fn, } });
      },
  
      Facturer(id) {
        this.dossierRef = id;
        this.facturation = true;
      },
  
      Facturons () {
              this.form.dossier = this.dossierRef;
              this.form.ref = this.refFacture;
              axios.put('https://socogetra.net/appi_socogetra/soco_ordre_facture.php', this.form)
              .then((response) => {
                  console.log(response);
                  this.listeDossiers();
                  this.popupActivo2 = false,
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'Dossier Facturé !',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
      }, 
  
      listeDossiers () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sogecit.net/appi_socogetra/depense_sogecit.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesExcel = response.data.data;
                this.donneesDossiers = response.data.data.reverse();
                this.donneesCat = response.data.categorie;
                this.donneesServ = response.data.service;
                console.log(this.donneesDossiers);
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          listeFiltre () {
            this.$vs.loading({
            type:'point',
            })
            const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;

              axios.get('https://sogecit.net/appi_socogetra/depense_filtre_sogecit.php?date_debut=' + dte_db + ' &date_fin=' + dte_fn + 'timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesDossiers = response.data.data.reverse();
                this.donneesCat = response.data.categorie;
                this.donneesServ = response.data.service;
                console.log(this.donneesDossiers);
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
      listeZones2 () {
            
            axios.get('https://socogetra.net/appi_sococit/albakoos_produits_clients.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesClient = response.data.data;
              this.$vs.loading.close()
              this.filtreUser();
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        ExportExcel(type, fn, dl) {
          const name = "RESUME COMPTABILITE";
            var elt = this.$refs.exportable_table;
            var wb = XLSX.utils.table_to_book(elt, {sheet:"Sheet JS"});
            return dl ?
              XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
            XLSX.writeFile(wb, fn || ((name + '.'|| 'SheetJSTableExport.') + (type || 'xlsx')));
          },

          ExportExcel2(type, fn, dl) {
            const name = "RESUME "+this.valueSelect;
            var elt = this.$refs.exportable_tablee;
            var wb = XLSX.utils.table_to_book(elt, {sheet:"Sheet JS"});
            return dl ?
              XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
            XLSX.writeFile(wb, fn || ((name + '.'|| 'SheetJSTableExport.') + (type || 'xlsx')));
          },

          ExportExcel3(type, fn, dl) {
            const name = "RESUME "+this.valueService;
            var elt = this.$refs.exportable_tablee;
            var wb = XLSX.utils.table_to_book(elt, {sheet:"Sheet JS"});
            return dl ?
              XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
            XLSX.writeFile(wb, fn || ((name + '.'|| 'SheetJSTableExport.') + (type || 'xlsx')));
          },
  
        Stats() {
              this.$vs.loading({
                type: 'point',
              });
              axios.get('https://socogetra.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.totalVoyages = 0;
                this.donneesStats = response.data;
                this.don_Clients = response.data.dn_clients;
                this.don_Clients.forEach((item) => {
                  this.totalVoyages = this.totalVoyages + parseInt(item.total_enregistrements);
                })
                console.log(this.don_Clients);
                this.$vs.loading.close();
              }, (error) => {
                console.log(error);
                this.$vs.loading.close();
              });
            },
  
            bilanArmateur () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://sygestra.com/appi_sygestra/bilan_armateur.php?id='+this.users.n_ent+'&timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesArmateurs = response.data.data;
              console.log(this.donneesArmateurs);
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        bilanZone () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_socogetra/soco_bilan_zone.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesZones = response.data.data;
              console.log(this.donneesArmateurs);
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        searchDate () {
            
            this.$vs.loading({
            type:'point',
            })
            const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
            fetch('https://socogetra.net/appi_sococit/albakoos_produits_clients_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
            .then(response => response.json())
            .then(data => {
              this.donneesClient = data.data;
              this.$vs.loading.close()
            });
  
            axios
                .get('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
                .then(response => (this.don_Clients = response.data.dn_clients))
  
        },
  
        StatsMonth () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_stats_month.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsMois = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        StatsWeek () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/Albakoos_stats_week.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsSemaine = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        listeDossier () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        formatPrice(value) {
          const formatter = new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 0
          });
          return formatter.format(value);
        },
  
        listeZones () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesZones = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          showClient(client) {
            this.ClientChoisi = client;
            console.log(this.ClientChoisi);
            this.popupActivo2 = true
          },
  
          generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
  
          exportToPDF() {
            html2pdf(document.getElementById("html-to-pdf"), {
              margin: 1,
              filename: "i-was-html.pdf",
            });
          },
  
          listeChauffeurs () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesChauf = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          verif() {
            if(this.users.conteneur == "KO") {
                this.$router.push({ path: '/dashboard' });
            }
          },
  
          Borderau(element) {
            this.elementChoisi = element;
            this.activePrompt9 = true;
            this.generateReport();
          },
  
          generatePDF () {
            var doc = new jsPDF();
            
            // Récupérer l'élément HTML que vous voulez convertir en PDF
            const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
            html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF avec une résolution de 300 DPI
              const imgData = canvas.toDataURL('image/png', 2.7);
              doc.addImage(imgData, 'PNG', 10, 10);
              
              // Sauvegarder le document PDF
              doc.save('example.pdf')
            })
          },
  
         // generatePDF () {
          //  var doc = new jsPDF();
  
            // Récupérer l'élément HTML que vous voulez convertir en PDF
          //  const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
          //  html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF
           //   const imgData = canvas.toDataURL('image/png')
            //  doc.addImage(imgData, 'PNG', 10, 10)
  
              // Sauvegarder le document PDF
           //   doc.save('example.pdf')
          //  })
         // },
  
          listeCamion () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesCamion = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          sheet () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://docs.google.com/spreadsheets/d/1hsitLRk83_0P5yJQUC6A9dNB8OQ2HfOI-9BGfy0VF3g/edit#gid=0')
              .then((response) => {
                this.donneesSheet = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          handleSelectChange(choix) {
            console.log(choix);
            const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
            this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
          },
  
        onSubmit() {
              this.donneesStats = [];
  
  
              fetch('https://socogetra.net/appi_sococit/albakoos_stats.php', {
                method: 'POST',
                body: JSON.stringify(this.formDate),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.don_Clients = response.dn_clients;
                console.log(this.don_Clients)
                return response.json();
              })
              .then(data => {
                this.donneesStats = data;
                this.don_Clients = data.dn_clients;
                console.log(this.don_Clients)
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Dossier OK!',
                  message: 'Dossier Crée',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },
  
        clickFn() {
          this.$refs.statistic.suspend(this.stop);
          this.stop = !this.stop;
        },
        handleClick(tab, event) {
          console.log(tab, event);
        },
        hilarity() {
          this.$notify({
            title: 'TRAVAIL DU JOUR TERMINE',
            message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
            duration: 0,
          });
        },
  
  
          getData() {
            const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA1REVBNjlBOUIxODE4RjI3RkIxNUQ2QzQ2QkI0NEU0IiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FjY291bnQubmF2aXMuY29tIiwibmJmIjoxNjg0MTAzMjY3LCJpYXQiOjE2ODQxMDMyNjcsImV4cCI6MTY4NDExMDQ2NywiYXVkIjpbImNvbXBhbnljbG91ZC1wdWJsaWNhcGkiLCJodHRwczovL2FjY291bnQubmF2aXMuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJjb21wYW55Y2xvdWQtcHVibGljYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJTbWFydEFjY2Vzcy1BcHAiLCJzdWIiOiI4NDVmNTIzYS0xMTBjLTQ4NjQtODVkNC1hYzE3NzlkN2YxZDAiLCJhdXRoX3RpbWUiOjE2ODQxMDMyNjYsImlkcCI6ImxvY2FsIiwiZW1haWwiOiJzYWtvbmVAc29jb2dldHJhLm5ldCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNha29uZUBzb2NvZ2V0cmEubmV0Iiwiam9pbmRhdGUiOiIwNS8wMy8yMDIzIDEwOjI1IiwicGljdHVyZSI6Imh0dHBzOi8vaW1hZ2VzLmFjY291bnQubmF2aXMuY29tL2VmZmVjNmUyLTIzNWQtNDNjMy05YmVmLTNlNTBkZDM0MmEwMS8xMDAucG5nIiwiZ2l2ZW5fbmFtZSI6IlNBS09ORSIsImZhbWlseV9uYW1lIjoiT3VtYXIiLCJzaWQiOiJFMkE2QzU4RkVDQzM0MDlFNjk4NDUzMURGNjc4RUMxQyJ9.rPA25RdckhXhl5sWBjVhI-C-8rIu3dX-w9zP2rk1Kwfpw8ED9LYV3J8d2sQknShx8vGTaZwSCiQLC55KNM1mi1b603uH8QEqz-Pb1Ql-wNWv_5NHIDdEG7d45G2KEX_CNjKBnNHS6agoatJbbjHQymdxmaudwQRqDJo9-mDXsG_ZUwD-vjYFQKvFqBzVJricJZiwrDAZXXIp9Yc2AvOVFsQs3DHXHk8GmY98q3-qt_Dfbr1UiJM-iejYzCO-dAa-YX2D78XK0UG9ejMcwKB1jBXpt0TgLMSHKIKcQSGVX5qPj_IHBB_dFgf3nNmrCz7mgoe4p1BCWHuItfk2iF7ccg'
            axios.get('https://api-smartaccess.cotedivoireterminal.com/smartaccess/api/gateway/vessel/visits', {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'accountIdentifier': 'takt',
                'accountType': 'Haulier',
                'partnerAccountIdentifier': 'cit',
                'appIdentifier': 'smrtaccess',
                'Authorization': 'Bearer '+token,
              }
            })
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.error(error);
            });
          }
      },
  
      mounted() {
        fetch('https://socogetra.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
        .then(response => response.json())
        .then(data => {
          this.don_Clients = data.dn_clients;
          this.don_Camion = data.don_Camion;
          console.log(data)
        })
        .catch(error => {
          console.error(error);
        });
        this.listeDossiers();
        this.listeCats();
  
      },
      
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .el-input__inner::placeholder {
      color: #201f1f;
  }
  
  </style>
  