<template>
  <div class="about" style="width: 100%; height: 100vh;">
    <div class="outer">
      <div class="middle">
        <div class="inner">
          <div style="padding: 10px; border-radius: 30px; background: white; width: 150px; margin: 0 auto">
            <center><img :src="users.logo" height="65"></center>
          </div>
          <vs-card class="login" style="width: 800px; margin: 0 auto; height: 200px; text-align: center">
              <h2 style="font-family: 'Quicksand', sans-serif;">Bonjour Monsieur <strong style="font-size: 22px; color:#271d79">{{users.nom.toUpperCase()}} {{users.prenom}}</strong></h2>
              <vs-divider/>
              <router-link to="/dashboard_conteneur"> <button class="button-dashe">Comptabilité</button> </router-link>
              <router-link to="/facturation_conteneur"> <button class="button-dashe">Banque</button> </router-link>
              <router-link to="/conteneur_ordres"> <button class="button-dashe">Factures</button> </router-link>
              
          </vs-card>
          <vs-card class="login" style="width: 800px; text-align:center; margin: 0 auto; height: 130px; background: #271d79">
            
          </vs-card>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'HelloWorld',
  computed: {
  users() {
    return this.$store.state.users;
      }
    },
  data() {
    return {
      erreur:false,
      mdp: "",
      connexionMessage: "",
      ip: "",
      vip: "",
      user: {
      name : '',
      administration : 'KO',
      benne : 'KO',
      citerne : 'KO',
      cloud : 'KO',
      comptabilite : 'KO',
      conteneur : 'KO',
      facturation : "KO",
      entreprise : '',
      n_ent : '1',
      identifiant : '',
      lecture : "KO",
      mdp : '',
      nom : '',
      photo : '',
      prenom : '',
      titre : '',
    },
    }
  },
  methods: {
        onEnter() {
          alert('lala');
        },
        deconnexion(){
          this.$vs.loading()
          
          this.$store.commit('Connexion', this.user);
                this.$router.push({ path: '/about' });
                  setTimeout( ()=> {
            this.$vs.loading.close()
          }, 2000);
        },
         getdata() {
            this.$vs.loading()
            let formData = new FormData();
            formData.append('user', this.user);
            formData.append('mdp', this.mdp);

            axios.post('https://sogecit.net/appi_socogetra/db_login_verif.php', formData,)
            .then((response) => {
              this.verif(response);
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
            });
          },

          verif(data) {
            let donnees = data.data.data[0];
            this.connexionMessage = data.data.mes
            if(data.data.valide == 1) {
              if(donnees.lieu == 2) {
                this.$store.commit('Connexion', donnees);
                this.$router.push({ path: '/' });
              }
              if(donnees.lieu == 1 && this.ip == this.vip) {
                  this.$store.commit('Connexion', donnees);
                  this.$router.push({ path: '/' });
                } else {
                  this.connexionMessage = 'Vous devez être au bureau pour vous connecter';
                  this.erreur=true;
                }
            }
            if(data.data.valide == 2) {
                this.erreur=true;
              }
          },

          Ip() {
          fetch('https://api.ipify.org?format=json')
          .then(x => x.json())
          .then(({ ip }) => {
              this.ip = ip;
              console.log(this.ip);
          });
        },
    },
    mounted() {
      this.Ip();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  /* Whatever width you want */
}
.button-dash {
  width: 180px;
  border-radius: 15px;
  height: 70px;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button-dash {
  background-color: rgba(128, 128, 128, 0.37); 
  color: white; 
  border: 2px solid white;
}

.button-dash:hover {
  border-radius: 15px;
  background-color: white;
  color: black;
}
.button-dashe {
  width: 200px;
  border-radius: 15px;
  height: 70px;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button-dashe {
  background-color: white; 
  color: black; 
  border: 2px solid #04AA6D;
}

.button-dashe:hover {
  border-radius: 15px;
  background-color: #04AA6D;
  color: white;
}
</style>
