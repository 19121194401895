import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import Dashboard from '../views/Dashboard.vue'

// ADMIN
import AdminDashboard from '../components/admin/Admin_dashboard.vue'

// CONTENEUR
import ConteneurDashboard from '../components/conteneurs/Dashboard.vue'
import AD_Ordres from '../components/conteneurs/Historique.vue'
import EX_Ordres from '../components/conteneurs/Historique_export.vue'
import EX_Ordres_liste from '../components/conteneurs/Liste_export.vue'
import Conteneur_recherche from '../components/conteneurs/Date_recherche.vue'
import AD_Armateurs from '../components/conteneurs/Armateurs.vue'
import Camion from '../components/conteneurs/Camion.vue'
import Chauffeur from '../components/conteneurs/Chauffeur.vue'
import Ordre_Details from '../components/conteneurs/Ordre_details.vue'
import Export_Details from '../components/conteneurs/Export_detail.vue'

import Facturation_conteneur from '../components/conteneurs/Facturation.vue'
import Facturation_conteneur_e from '../components/conteneurs/Facturation_e.vue'

// CITERNE
import CiterneDashboard from '../components/citernes/Dashboard.vue'
import Citerne_Camion from '../components/citernes/Camion.vue'
import Citerne_Chauffeur from '../components/citernes/Chauffeur.vue'
import Citerne_historique from '../components/citernes/Historique.vue'

// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  // ADMIN
  {
    path: '/dashboard_admin',
    name: 'Dashboard_admin',
    component: AdminDashboard
  },
  // CONTENEUR
  {
    path: '/dashboard_conteneur',
    name: 'Dashboard_conteneur',
    component: ConteneurDashboard
  },
  {
    path: '/conteneur_ordres',
    name: 'AD_Ordres',
    component: AD_Ordres
  },
  {
    path: '/ordres_details',
    name: 'Ordre_Details',
    component: Ordre_Details
  },
  {
    path: '/export_details',
    name: 'Export_Details',
    component: Export_Details
  },
  {
    path: '/conteneur_export',
    name: 'EX_Ordres',
    component: EX_Ordres
  },
  {
    path: '/conteneur_export_liste',
    name: 'EX_Ordres_liste',
    component: EX_Ordres_liste
  },
  {
    path: '/conteneur_recherche',
    name: 'Conteneur_recherche',
    component: Conteneur_recherche
  },
  {
    path: '/armateurs',
    name: 'AD_Armateurs',
    component: AD_Armateurs
  },
  {
    path: '/camion',
    name: 'Camion',
    component: Camion
  },
  {
    path: '/chauffeur',
    name: 'chauffeur',
    component: Chauffeur
  },
  // CONTENEUR
  {
    path: '/dashboard_citerne',
    name: 'Dashboard_citerne',
    component: CiterneDashboard
  },
  {
    path: '/citerne_camion',
    name: 'Citerne_Camion',
    component: Citerne_Camion
  },
  {
    path: '/citerne_chauffeur',
    name: 'Citerne_chauffeur',
    component: Citerne_Chauffeur
  },
  {
    path: '/citerne_historique',
    name: 'Citerne_historique',
    component: Citerne_historique
  },
  {
    path: '/facturation_conteneur',
    name: 'Facturation_conteneur',
    component: Facturation_conteneur
  },
  {
    path: '/facturation_conteneur_e',
    name: 'Facturation_conteneur_e',
    component: Facturation_conteneur_e
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/doc'+process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
 // const authenticated = store.state.users && store.state.users.name ? store.state.users.name.length : 0;
//  console.log(to.path);
//  console.log(authenticated);
//  console.log(from.path);

//  if (to.path !== '/about' && to.path !== '/administration/detailss') {
 //   if (authenticated > 2) {
      //location.reload();
   //   next();
 //   } else {
   //   next('/about?error=1');
  //  }
 // } else {
 //   next();
 // }
// });

export default router
